////
/// @group _system/
////

// ваш код
.noscript-msg {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	clear: both;
	font: normal normal 13px/1.2em $font-family--main;
	backface-visibility: hidden;
	outline: none;
	padding: 0;
	margin: 0;
	border-width: 0;

	&__input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;

		&:checked + .noscript-msg__container {
			display: none;
		}
	}

	&__container {
		padding: .75em 1.5em;
		background-color: #f7fc9c;
		border-top: 3px solid #f7b38b;

		@include clearfix();
	}

	&__close {
		display: none;
		display: block;
		float: right;
		width: 1em;
		height: 1em;
		font-size: 3em;
		margin-left: .5em;
		text-align: center;
		line-height: 1em;
		cursor: pointer;

		&:hover {
			background-color: rgba(#000, .075);
		}

		&:active {
			background-color: rgba(#000, .15);
		}
	}

	&__wezom-link {
		display: inline-block;
		text-decoration: none;
		width: 50px;
		height: 18px;
		margin: .75em 1em 1em 0;
		background: transparent url("./pic/wezom-noscript-logo.png") 0 0 no-repeat;

		@include media($sm) {
			float: left;
			margin-top: .2em;
		}
	}

	&__content {
		overflow: hidden;

		> :first-child {
			margin-top: 0;
		}

		p {
			margin: .5em 0;
		}
	}
}
