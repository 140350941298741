////
/// @group _settings/
////

/// Основной шрифт
/// по умолчанию будет добавлен к `html`
/// @type Keyword
$font-family--main: "Museo Sans Cyrl", sans-serif;

/// Дополнительный шрифт
/// @type Keyword
$font-family--sub: "PF DinDisplay Pro", sans-serif;

/// Дополнительный шрифт
/// @type Keyword
$font-family--italic: Georgia, "Times New Roman", Times, serif;

/// Дополнительный шрифт
/// @type Keyword
$font-family--addon: "Roboto", sans-serif;

/// Шрифт "кода"
/// по умолчанию будет добавлен к `pre, code, samp, kbd`
/// @type Keyword
$font-family--monospace: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
